import React from 'react';
import centerLineBg from '../assets/images/hero-image.png';
import centerLine from '../assets/images/center-line.png';
import Spline from '@splinetool/react-spline';

const CenterLineSection: React.FC = () => {
  return (
    <section className="relative bg-[#101010] py-20 flex flex-col xs:flex-col md:flex-row items-center max-w-custom-1600 mx-auto overflow-visible items-center justify-center">

      {/* Right Column (Image) */}
      <div className="xs:w-[100%] sm:w-1/2 h-[350px] flex justify-center mt-10 md:mt-0 relative">
        <img
          src={centerLineBg}
          alt="Hero"
          className="origin-center rotate-45 opacity-[.30] w-full h-[1300px] max-w-lg object-cover absolute right-[90%] md:right-[95%] -top-[400px] md:-top-[190px]"
          style={{ overflow: 'visible' }}
        />
        <img
          src={centerLine}
          alt="Hero"
          style={{ overflow: 'visible' }}
        />
        <div className="w-[600px] md:hidden lg:block max-w-[600px] h-[500px] object-cover absolute left-[65%] -top-[20px]">
          <Spline scene="https://prod.spline.design/MxhsJqLd-FGvr3b1/scene.splinecode" />
        </div>
      </div>

    </section>
  );
};

export default CenterLineSection;
