import React from 'react';
import heroImage from '../assets/images/hero-image.png';
import arrowDown from '../assets/images/arrow-down.png';
import Spline from '@splinetool/react-spline';

const HeroSection: React.FC = () => {
  return (
    <section className="relative bg-[#101010] py-20 flex flex-col xs:flex-col md:flex-row items-center justify-between max-w-custom-1600 mx-auto overflow-visible">

      {/* Left Column */}
      <div className="xs:w-[100%] md:w-1/2 text-white space-y-5 z-[10]">
        {/* Subheading */}
        <h3 className="text-center md:text-left uppercase font-mentalist text-[#DCDCDC]/75 text-[7px] sm:text-[12px] md:text-[10px] leading-[10px] font-[700]">Revolutionary hub</h3>
        {/* Main Heading */}
        <h1 className="text-center md:text-left font-nexa-heavy font-[800] text-[26px] sm:text-[64px] md:text-[48px] leading-[31px] sm:leading-[76px] md:leading-[58px] text-[#DCDCDC]">Evidence
          <br />Based Research</h1>
        {/* Divider */}
        <hr className="hidden md:flex border-t-2 border-white/25 w-[400px] my-4" />
        {/* Paragraph */}
        <p className="text-center md:text-left font-nexa-light font-[900] text-[14px] sm:text-[21px] leading-[24px] md:w-[470px] pt-[15px] px-[20px] md:px-[0px]">
          <span className='text-[#DCDCDC]/75'>Edge Forge is an </span>
          <span className='text-[#DCDCDC]/100'>aggregator of Financial Markets research paper</span>
          <span className='text-[#DCDCDC]/75'>, reviewed and selected by a staff of Quant Analysts, Professional Traders and Mathematicians.</span>
        </p>
        <div className='flex flex-col space-y-[10px] md:space-y-[70px] pt-[15px] items-center md:items-start'>
          {/* Full-width Button */}
          <button className="text-center flex uppercase bg-[#DCDCDC] text-[#101010] font-mentalist font-[700] text-[12px] leading-[12px] py-[19px] px-8 rounded-[80px] w-[200px] justify-center items-center">
            <a href="#contact">Contact Us</a>
          </button>
          {/* Arrow Down Button */}
          <button className="hidden md:flex text-white p-3 rounded-full pt-[20px] md:pt-[10px] ml-[-10px]">
            <a href="#contact">
              <img src={arrowDown} alt="Menu" className="xs:h-[16px] xs:w-[30px] sm:h-[12px] sm:w-[25px]" />
            </a>
          </button>
        </div>
      </div>

      {/* Right Column (Image) */}
      <div className="special-blur xs:w-[100%] sm:w-[1/2] flex justify-center mt-10 md:mt-0 relative">
        <Spline scene="https://prod.spline.design/JurY38t3awWe3bvo/scene.splinecode" />
      </div>
      {/* <div style={{ width: '100vw', height: '100vh', background: 'transparent' }}>
        <Spline scene="https://prod.spline.design/JurY38t3awWe3bvo/scene.splinecode" />
      </div> */}
    </section>
  );
};

export default HeroSection;
